<template>
  <div class="main_1">

    <Banner1/>

    <Banner2/>

    <Banner3/>

    <Banner4/>

    <Banner5/>

    <Banner6/>

    <Banner7/>

  </div>
</template>

<script>
import Banner1 from "@/components/Banner1";
import Banner2 from "@/components/Banner2";
import Banner3 from "@/components/Banner3";
import Banner4 from "@/components/Banner4";
import Banner5 from "@/components/Banner5";
import Banner6 from "@/components/Banner6";
import Banner7 from "@/components/Banner7";

export default {
  name: "Main",
  components: {
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7
  }

}
</script>

<style scoped>

</style>