<template>
  <div id="app">
    <Header/>

    <Main/>

    <Footer/>
  </div>
</template>

<script>
import Main from "@/components/Main";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import Banner1 from "@/components/Banner1";
import Banner2 from "@/components/Banner2";
import Banner3 from "@/components/Banner3";
import Banner4 from "@/components/Banner4";
import Banner5 from "@/components/Banner5";
import Banner6 from "@/components/Banner6";
import Banner7 from "@/components/Banner7";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/js/script';
import './assets/css/style.css';

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Main,
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7
  }
}
</script>

<style>
</style>
