<template>
  <section class="img_banner3">
    <div class="container">
<!--      <img class="logo_banner3"-->
<!--           src="https://quantumcomodidade.com.br/wp-content/uploads/2020/09/quantum-reconhecimento-facial-homem-400x400.png"-->
<!--           alt="">-->
      <div class="banner3">
        <div>
            <h3 class="titulo_banner_3">Como funciona</h3>
            <div id="cont">
                <h2>
                    O sistema é comandado pelo porteiro através de um tablet. Ele<br>
                    realiza a comunicação com o dispositivo de reconhecimento <br>
                    facial e com as câmeras de identificação de documentos e placas.
                </h2>

                <h2 style="color: #000;">
                    O porteiro será notificado sempre que alguém entrar no<br>
                    condomínio, com a visualização dos dados de identificação e<br>
                    fotos.
                </h2>

                <h2>
                    A solução fornece o acesso ao site administrativo do sistema,<br>
                    onde o gestor poderá acessar o cadastro das informações do<br>
                    condomínio (moradores, veículos, visitantes, prestadores de<br>
                    serviço, etc.) e os relatórios de eventos ocorridos.
                </h2>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner3"
}
</script>

<style scoped>
.banner3 {
    height: 100vh !important;
    width: 100% !important;
    align-items: center;
    display: flex;
}
.banner3 div {
    width: 100%;
}
</style>