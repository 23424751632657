<template>
  <section class="img_banner4">
    <div class="container">
        <div class="banner4">
          <h3 class="titulo_banner_4">Benefícios</h3>
          <p class="txt_banner4">Na contratação dos serviços de portaria 24h com a Quantum Comodidade Condominial,<br>
            pelo período mínimo de 1 ano, toda a implantação, infraestrutura e equipamentos <br>
            possuem custo zero! Consulte-nos para maiores informações. <br></p>
        </div>
        <img class="logo_banner4"
             src="https://quantumcomodidade.com.br/wp-content/uploads/2020/10/quantum-tela-app-2-400x279.png" alt="">
      </div>
  </section>
</template>

<script>
export default {
  name: "Banner4"
}
</script>

<style scoped>
</style>