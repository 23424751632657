<template>
    <section class="img_banner1">
        <video id="homevideo" autoplay loop src="../assets/video.mp4"></video>
        <div class="container">

            <div class="banner1">
                <h1 class="titulo_banner_1"><br><br><br><br>Simples, fácil, acessível <br>e nada igual no mercado.</h1>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Banner1"
}
</script>

<style>
.banner1 {
    height: 100vh !important;
    align-items: center;
    justify-content: center;
    display: flex;
}

.banner1 h1 {
    font-size: 70px;
    opacity: 0.8;
}

#homevideo {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

</style>