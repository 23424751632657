<template>
  <section class="img_banner6">
    <div class="container">
<!--      <img class="logo_banner6"-->
<!--           src="https://quantumcomodidade.com.br/wp-content/uploads/2020/09/quantum-portaria-ja-trio-300x225.png"-->
<!--           alt="">-->
      <div class="banner6">

        <div>
            <h3 class="titulo_banner_6">Solução para a segurança <br> do seu condomínio</h3>
            <h5>Aumente a segurança do seu <br> condomínio com o PortariaJá!</h5>
            <ul>
                <li>Produto exclusivo</li>
                <li>Simples de usar</li>
                <li>Tecnologia inovadora</li>
                <li>Melhor custo x benefício</li>
            </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner6"
}
</script>

<style scoped>
.banner6 {
    align-items: flex-end;
    display: flex;
    height: 100vh !important;
    padding-bottom: 100px;
}
</style>