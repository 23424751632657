<template>
  <div class="contato">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14628.790337823988!2d-46.65867588502221!3d-23.561346184682893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-BR!2sbr!4v1611382466563!5m2!1spt-BR!2sbr" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <div class="container">
    <div class="row">
      <section class="banner7_formulario">



        <form action="">
          <div class="formulario">
          <h3 class="titulo_banner_7">Entre em contato conosco.</h3><br>
            <input class="form-control" type="text" placeholder="Nome">

            <input class="form-control" type="text" placeholder="E-mail">

            <input class="form-control" type="text" placeholder="Telefone">

            <textarea class="form-control" name="" id="" cols="25" rows="7" placeholder="Mensagem"></textarea>

            <button class="btn btn-success">Enviar</button>
          </div>
        </form>
      </section>
    </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Banner7"
}
</script>

<style scoped>
.contato {
    position: relative;
}
.contato iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
</style>