<template>
    <div id="app">
        <Header/>

        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <br>
                    <br>
                    <br>
                    <br>
                    <h1>Exclusão de contas</h1>
                    <br>
                    <br>
                    <h2>MINHA PORTARIA JÁ</h2>
                    <br>
                    <br>
                    <p>Para solicitar a exclusão total dos seus dados, é necessario entrar em contato com o sindico do seu condominio.</p>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Main from "@/components/Main";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import Banner1 from "@/components/Banner1";
import Banner2 from "@/components/Banner2";
import Banner3 from "@/components/Banner3";
import Banner4 from "@/components/Banner4";
import Banner5 from "@/components/Banner5";
import Banner6 from "@/components/Banner6";
import Banner7 from "@/components/Banner7";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/js/script';
import './assets/css/style.css';

export default {
    name: 'ExclusaoConta',
    components: {
        Header,
        Footer,
        Main,
    }
}
</script>

<style>
h2 {
    margin: 40px 0;
}
</style>
