<template>
  <section class="img_banner2">
    <div class="container">
      <div class="banner2">
        <h3 class="titulo_banner_2">Toda a segurança do seu condomínio em um sistema</h3>
        <h1>interativo, prático e seguro !</h1>
      </div>
<!--      <img class="logo_banner2"-->
<!--           src="https://quantumcomodidade.com.br/wp-content/uploads/2020/10/quantum-tela-app.png">-->
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner2"
}
</script>

<style scoped>

</style>