import Vue from 'vue'
import App from './App.vue'
import Politica from "@/Politica.vue";
import ExclusaoConta from "@/ExclusaoConta.vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

const routes = {
  '/': App,
  '/politica-de-privacidade': Politica,
  '/exclusao-de-dados': ExclusaoConta
}

new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute] || App
    }
  },
  render (h) { return h(this.ViewComponent) }
})