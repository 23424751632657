<template>
    <div id="app">
        <Header/>

        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <br>
                    <br>
                    <br>
                    <br>
                    <h1>TERMO DE ACEITE DAS CONDIÇÕES DE USO DO APLICATIVO</h1>
                    <br>
                    <br>
                    <h2>MINHA PORTARIA JÁ</h2>
                    <br>
                    <br>
                    <p>O sistema (APP) MINHA PORTARIA JÁ está disponível para utilização através dos sistemas Android e IOS, bem como demais sistemas para smartphone compatíveis.</p>
                    <p>A marca MINHA PORTARIA JÁ (APP) encontra-se registrada no INPI e tem como titular PRESSAGIO SERVIÇOS DE INFORMÁTICA EIRELI, inscrita no CNPJ sob o nº 35.779.440/0001-73, doravante TITULAR.</p>
                    <p>Os direitos autorais sobre a marca MINHA PORTARIA JÁ, inclusive textos, imagens, fotografias, vídeos, sons e quaisquer outros dados gerados a partir da utilização do sistema pertencem ao TITULAR da marca, doravante denominado simplesmente TITULAR.</p>
                    <p>O acesso ao sistema MINHA PORTARIA JÁ não gera ao USUÁRIO qualquer direito de propriedade intelectual sobre o aplicativo, bem como fica o USUÁRIO expressamente proibido de fazer uso, exploração parcial ou total, ainda que a título gratuito da marca MINHA PORTARIA JÁ.</p>
                    <p>Para fazer uso da marca ou exibição dela é necessária a expressa autorização de forma escrita do TITULAR.</p>
                    <p>O presente TERMO já se encontra atualizado de acordo com as disposições da Lei 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD) e suas alterações. Para os efeitos da referida lei, o titular dos dados pessoais eventualmente objeto de tratamento é identificado no presente TERMO como USUÁRIO; o agente de tratamento dos dados é identificado no presente TERMO como TITULAR.</p>
                    <p>O sistema MINHA PORTARIA JÁ em momento algum trata dados pessoais classificados como sensíveis pela legislação vigente, salvo aqueles livremente ofertados pelo USUÁRIO como condição de funcionalidade do APP e dizentes com a finalidade deste.</p>
                    <h2>I. DEFINIÇÃO DO PRODUTO E FINALIDADE</h2>
                    <p>O aplicativo é uma ferramenta de meio eletrônico de controle de acesso do USUÁRIO ao estabelecimento (condomínio). </p>
                    <p>O aplicativo em nenhum momento tem ingerência sobre a possibilidade de acesso salvo através daqueles dados disponibilizados pelo próprio USUÁRIO.</p>
                    <h2>II. ACEITAÇÃO DAS CONDIÇÕES DE USO DO APLICATIVO</h2>
                    <p>A utilização do presente APP pressupõe a prévia, expressa e irrestrita aceitação de todos os seus termos e condições de uso expressas no presente instrumento.</p>
                    <p>Fica desde já fixado que a MINHA PORTARIA JÁ poderá a qualquer tempo alterar o conteúdo e termos desse instrumento, cabendo ao USUÁRIO a reafirmação expressa de seu consentimento para a utilização do APP, sem o que o uso poderá ficar bloqueado.</p>
                    <h2>III. OPERACIONALIDADE DO SISTEMA</h2>
                    <p>O APP funcionará 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, sendo o TITULAR responsável por fornecer as soluções técnicas que mantenham o sistema operacional válido. Para tanto, poderá o TITULAR a qualquer tempo e por seu exclusivo critério:</p>
                    <p>- Suspender, interromper ou limitar o acesso a todo ou a parte do aplicativo a todos os USUÁRIOS ou a um grupo restrito de USUÁRIOS;</p>
                    <p>- Remover toda a informação que possa perturbar o funcionamento do aplicativo ou que esteja em conflito com normas de Direito brasileiro ou internacional.</p>
                    <p>- Realizar manutenções, revisões e atualizações necessárias. </p>
                    <h2>IV. CADASTRO PARA UTILIZAÇÃO DO APLICATIVO</h2>
                    <p>Poderão realizar o cadastro todas as pessoas físicas capazes e maiores de 18 anos, ou menores autorizadas por responsáveis, os quais assim se declararão no ato de acesso (download) do aplicativo.</p>
                    <p>Cada USUÁRIO poderá ser titular de uma única conta junto ao APP.</p>
                    <p>O código de acesso e senha de cada conta será administrado única e exclusivamente pelo próprio USUÁRIO, observado o disposto no item VI abaixo.</p>
                    <p>O USUÁRIO deverá fornecer todos os dados necessários ao cadastro, cuja guarda é regida pela Lei 13.709/2018. Os dados declarados expressamente pelo USUÁRIO reputar-se-ão válidos e verdadeiros a partir de sua declaração ao sistema do APP.</p>
                    <p>Eventuais comunicações entre USUÁRIO e APP serão realizadas dentro do próprio aplicativo e por e-mail, mediante o fornecimento de conta válida e cuja manutenção caberá exclusivamente ao USUÁRIO.</p>
                    <p>Após a confirmação do cadastro, o USUÁRIO receberá login (apelido) e senha por cuja guarda e zelo será o único responsável.</p>
                    <p>A qualquer tempo o USUÁRIO poderá requerer o cancelamento de sua conta.</p>
                    <p>Nos termos da LGPD (artigo 7º, I e § 5º) o USUÁRIO neste ato concede autorização expressa ao APP MINHA PORTARIA JÁ para cessão e transferência dos dados armazenados no aplicativo a terceiros com ou sem vínculo jurídico com o APP.</p>
                    <p>A qualquer tempo poderá o USUÁRIO solicitar o cancelamento de sua participação no sistema MINHA PORTARIA JÁ, devendo para tanto enviar um e-mail para cancelamento@portariaja.com.</p>
                    <h2>São responsabilidades do TITULAR:</h2>
                    <p>- Manter os dados armazenados protegidos;\n- Manter na plataforma somente USUÁRIOS que preencham os requisitos mínimos de operação;\n- Manter atualizado o sistema.</p>
                    <h2>São responsabilidades do USUÁRIO:</h2>
                    <p>- Utilizar corretamente o sistema e seus serviços de acordo com este TERMO;\n- Manter guarda e zelo de seu login e senha de acesso, não os fornecendo a terceiros;\n- Manter atualizados os dados fornecidos;\n- Patrocinar a suas expensas sinal de internet ou dados que possibilitem acesso ao sistema.</p>
                    <h2>V. LINKS EXTERNOS E PARCEIROS</h2>
                    <p>O sistema MINHA PORTARIA JÁ se reserva o direito de incluir e excluir parceiros de acordo com sua livre conveniência, sempre no uso do direito de defesa da marca e do sistema do aplicativo.</p>
                    <h2>VI. POLÍTICA DE PRIVACIDADE E CONSERVAÇÃO E TRATAMENTO DOS DADOS</h2>
                    <p>O sistema MINHA PORTARIA JÁ tem ciência do direito do USUÁRIO de não ter seus dados tratados de qualquer forma que não seja para o estrito cumprimento dos desígnios de segurança e controle de acesso do APP, na forma da Lei 13.709/2018 e do Código Civil.</p>
                    <p>A política de proteção de dados no País é regida por lei federal, estando, pois, sujeita a modificações periódicas. Cabe ao USUÁRIO sistematicamente revisar os termos de uso do aplicativo e em caso de dúvida contatar o TITULAR.</p>
                    <p>O sistema MINHA PORTARIA JÁ assegura a cada USUÁRIO todos os direitos previstos em lei, colocando seus canais de comunicação à disposição: contato@portariaja.com .</p>
                    <p>Em qualquer contato o USUÁRIO deverá se identificar de forma completa e fazer um breve relato do assunto sobre o qual deseja esclarecimento.</p>
                    <p>Fica autorizado o tratamento dos dados armazenados no sistema MINHA PORTARIA JÁ, independentemente de consentimento expresso do USUÁRIO, nas hipóteses previstas em lei, bem como para: \n - Cumprimento de obrigação legal ou judicial;\n- Realização de estudos que visem à finalidade do APP (controle de acesso e segurança do condomínio), garantido o anonimato do USUÁRIO;\n- Proteção à saúde e à vida do USUÁRIO ou terceiro afetado;\n- Proteção de interesses legítimos que não firam desproporcionalmente a liberdade individual do USUÁRIO.</p>
                    <h2>VII. SEGURANÇA OFERTADA</h2>
                    <p>O sistema MINHA PORTARIA JÁ utiliza certificado SSL (secure socket layer) que garante que os dados pessoais sejam transmitidos de forma segura e confidencial e cifrada entre os USUÁRIOS e o servidor.</p>
                    <p>O TITULAR fica isento de responsabilidade pelo uso indevido das chaves de acesso ao sistema (login e senha) de cada USUÁRIO, inclusive quando decorrente de ataque de hacker ou craker.</p>
                    <h2>VIII. POSSIBILIDADE DE AVALIAÇÃO DAS FUNCIONALIDADES DO APP</h2>
                    <p>O sistema MINHA PORTARIA JÁ poderá contar com sistema de avaliação de suas funcionalidades, pelo qual o USUÁRIO atribuirá nota ao desempenho do APP, a partir do sistema de apontamento crescente representado por estrelas que irão variar de 1 a 5.</p>
                    <p>A identidade do USUÁRIO avaliador é anonimizada.</p>
                    <h2>IX. VERSÃO E FORO DE ELEIÇÃO</h2>
                    <p>O presente protocolo representa a versão 1 dos termos de uso, editado no mês de Maio de 2022.</p>
                    <p>Fica eleito o foro da Cidade de São Paulo para dirimir quaisquer dúvidas relativas à utilização do presente aplicativo.</p>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Main from "@/components/Main";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import Banner1 from "@/components/Banner1";
import Banner2 from "@/components/Banner2";
import Banner3 from "@/components/Banner3";
import Banner4 from "@/components/Banner4";
import Banner5 from "@/components/Banner5";
import Banner6 from "@/components/Banner6";
import Banner7 from "@/components/Banner7";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/js/script';
import './assets/css/style.css';

export default {
    name: 'app',
    components: {
        Header,
        Footer,
        Main,
    }
}
</script>

<style>
h2 {
    margin: 40px 0;
}
</style>
