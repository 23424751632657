<template>
  <footer>
<!--    <div class="footer_01">-->
<!--      <div class="container">-->
<!--        <div class="row logo_footer_02">-->
<!--          <div class="logo_footer">-->

      Powered by <img class="logo_footer" alt="Portaria-ja Logo" src="@/assets/images/favicon.png"> PortariaJá! {{ano}}

<!--          </div>-->
<!--          <p>Integer efficitur erat et neque porta accumsan.-->
<!--            Nunc sollicitudin sagittis massa, quis viverra nibh tempus id.-->
<!--            Praesent metus nunc, finibus quis neque at, sodales varius purus.</p>-->

<!--          <div class="row contato_footer_01">-->
<!--            <div class="contato_footer_02">-->
<!--              <b-icon icon="telephone" font-scale="2"></b-icon>-->
<!--              <p>Entre em contato conosco</p>-->
<!--              <h6 class="tel"><a href="tel:551132803773">(11) 3280-3773</a></h6>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row lista_footer_01">-->
<!--          <div class="row lista_footer_02">-->
<!--            <h3>Links Importantes</h3>-->
<!--            <ul class="lista_footer">-->
<!--              <li><a href="">Sobre a Empresa</a></li>-->
<!--              <li><a href="">Projetos mais recentes</a></li>-->
<!--              <li><a href="">Novidades do blog</a></li>-->
<!--              <li><a href="">Nossos depoimentos</a></li>-->
<!--              <li><a href="">Nossa missão</a></li>-->
<!--              <li><a href="">Contate-nos</a></li>-->
<!--              <li><a href="">Obter cotação</a></li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row servicos_footer_01">-->
<!--          <div class="row servicos_footer_02">-->
<!--            <div class="servicos_footer">-->
<!--              <h3>Nossos Serviços</h3>-->
<!--              <ul class="lista_footer">-->
<!--                <li>-->
<!--                  <img class="img_footer_1" alt="Imagem de Nossos Servicos"-->
<!--                       src="@/assets/images/nossos_servicos_image1.jpg">-->
<!--                  <h5 class="txt_img1_footer"><a href="">Nulla feugiat, enim ac ultrices.</a></h5>-->
<!--                </li>-->

<!--                <li>-->
<!--                  <img class="img_footer_2" src="@/assets/images/nossos_servicos_img2.jpg"-->
<!--                       alt="Imagem de Nossos Servicos">-->
<!--                  <h5 class="txt_img2_footer"><a href="">Nulla feugiat, enim ac ultrices.</a></h5>-->
<!--                </li>-->

<!--                <li>-->
<!--                  <img class="img_footer_3" src="@/assets/images/nossos_servicos_image3.jpg"-->
<!--                       alt="Imagem de Nossos Servicos">-->
<!--                  <h5 class="txt_img3_footer"><a href="">Nulla feugiat, enim ac ultrices.</a></h5>-->
<!--                </li>-->
<!--              </ul>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row inscricao_footer">-->
<!--          <div class="row inscricao_footer_1">-->
<!--            <h3>Registre-se</h3>-->
<!--            <form method="post" action="#">-->
<!--              <label>-->
<!--                <input type="email" name="email" placeholder="  Insira seu E-mail">-->
<!--              </label>-->
<!--              <button class="botao_footer" type="submit">Salvar</button>-->
<!--            </form>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="copyright_section">-->
<!--          <p class="copyright">© Copyright - PortariaJa 2020</p>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
  </footer>
</template>

<script>
export default {
  name: "Footer",
    data(){
      return {
          data: new Date().getYear()
      }
    }
}
</script>

<style scoped>
</style>