<template>
  <header>
    <div class="header_1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 logo">
            <img class="logo_header" alt="Vue logo" src="@/assets/images/logo-horizontal-portariaja.png">
          </div>

          <div class="col-6 infos d-none d-md-block ">
            <div class="row text-right">
              <div class="col-9">

                <h5 ><b-icon class="logo_envelope" icon="envelope" font-scale="1.5"></b-icon> E-mail</h5>
                <p class="email" style="font-weight: 600"><a href="mailto:contato@portariaja.com">contato@portariaja.com</a></p>
              </div>

              <div class="col-3">

                <h5><b-icon class="logo_telefone" icon="telephone" font-scale="1.5" /> Telefone</h5>
                <p class="tel" style="font-weight: 600"><a href="tel:551132803773">(11) 3280-3773</a></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

<!--    <div class="header_2">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-10">-->
<!--            <b-nav>-->
<!--              <b-nav-item>Home</b-nav-item>-->
<!--              <b-nav-item>Sobre</b-nav-item>-->
<!--              <b-nav-item>Serviços</b-nav-item>-->
<!--              <b-nav-item>Projetos</b-nav-item>-->
<!--              <b-nav-item>Novidades</b-nav-item>-->
<!--              <b-nav-item>Loja</b-nav-item>-->
<!--              <b-nav-item>Contato</b-nav-item>-->
<!--              <b-nav-item>Páginas</b-nav-item>-->
<!--            </b-nav>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
@media (max-width: 800px) {
    .logo {
        text-align: center;
    }
}
</style>