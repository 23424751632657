<template>
  <section class="img_banner5">
    <div class="container">
      <div class="banner5">
        <h3 class="titulo_banner_5">Diferenciais</h3>

          <div class="row colunas5">
              <!-- Text slides with image -->
              <div class="col-sm-4">
                  <div class="img"><img src="@/assets/images/quantum-reconhecimento-facial-homem-200x171.png" alt=""></div>
                  <h6>Reconhecimento facial de pessoas COM ou SEM máscara</h6>
                  <p>O processo de reconhecimento se dá através da leitura da face em 3D, garantindo um alto grau de
                      precisão
                      e
                      impossibilitando qualquer outro método realizado, como por exemplo, por uma foto.</p>
              </div>

              <!-- Slides with custom text -->
              <div class="col-sm-4">
                  <div class="img"><img src="@/assets/images/quantum-reconhecimento-placa-1-250x180.png"></div>
                  <h6>Leitura digital de documentos (RG ou CNH)</h6>
                  <p>A partir da digitalização da imagem do documento, o sistema identifica os dados da pessoa e consolida
                      as
                      informações</p>
              </div>

              <!-- Slides with image only -->
              <div class="col-sm-4">

                  <div class="img"><img src="@/assets/images/quantum-seguranca-250x139.png"></div>
                  <h6>Identificação automática de placas de veículos e leitura digital de documentos</h6>
                  <p>A partir da digitalização da imagem da placa do veículo, o sistema identifica as informações e associa
                      ao
                      cadastro do morador responsável.</p>
              </div>

              <!-- Slides with img slot -->
              <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->


          </div>

          <div class="row colunas5">
              <div class="col-sm-4">
                  <div class="img"><img src="@/assets/images/quantum-tela-app-2-250x174.png"></div>
                  <h6>Interface de manuseio extremamente simples para o uso do porteiro</h6>
                  <p>Interface interativa e rápida. Fornecemos todo o treinamento.</p>
              </div>

              <!-- Slide with blank fluid image to maintain slide aspect ratio -->
              <div class="col-sm-4">
                  <div class="img"><img src="@/assets/images/quantum-tela-app-3-250x174.png"></div>
                  <h6>Todos os dados e informações protegidos de acordo com a LGPD</h6>
                  <p>O primeiro passo para a proteção de dados na LGPD (Lei Geral de Proteção de Dados) é garantir o
                      consentimento das informações pelo seu titular. Este consentimento representa não apenas uma
                      autorização,
                      mas uma garantia para o tratamento das informações coletadas.</p>
              </div>

              <div class="col-sm-4">

                  <div class="img"><img src="@/assets/images/quantum-wifi-nowifi-250x139.png"></div>
                  <h6>O sistema pode funcionar COM ou SEM acesso à Internet</h6>
                  <p>O sistema pode operar nos modos online e offline, garantindo o seu funcionamento mesmo quando não há
                      conexão com a internet. Todas as informações são sincronizadas remotamente na nuvem quando a conexão com
                      a
                      internet for restabelecida.</p>
              </div>
          </div>

<!--        <b-carousel-->
<!--            id="carousel-10"-->
<!--            v-model="slide"-->
<!--            :interval="4000"-->
<!--            controls-->
<!--            indicators-->
<!--            style="text-shadow: 1px 1px 2px #333;"-->
<!--            @sliding-start="onSlideStart"-->
<!--            @sliding-end="onSlideEnd"-->
<!--        >-->

<!--          -->
<!--            -->
<!--        </b-carousel>-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner5",
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>

<style>
.carousel-caption {
  position: relative !important;
  left: 0 !important;
  right: 0 !important;
}

#carousel-10 {
  width: 100%;
}

.banner5 .carousel-inner {
  width: 100%;
}

.colunas5 {
    color: #fff;
    text-align: center;
}
.colunas5 +.colunas5 {
    margin-top: 80px;
}

.colunas5 h6 {
    font-weight: bold;
}
.colunas5 p {
    font-weight: 300;
    font-size: 13px;
}
.colunas5 .img {
    height: 140px;
    margin-bottom: 30px;
    text-align: center;
}
.colunas5 .img img {
    height: 100%;
}

</style>